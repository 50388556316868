import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { thunk } from "redux-thunk";
import { ToastContainer } from "react-toastify";
import reducers from "./redux/reducers/_index.reducer"; // import reducer thay đổi store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Tạo store
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      draggable
      pauseOnHover={true}
      hideProgressBar={true}
      newestOnTop={false}
      pauseOnFocusLoss={true}
      closeOnClick
      limit={3}
    />
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
